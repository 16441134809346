<div *ngIf="!selectedItemsLoaded">
  <se-fe-spinner [seFeDataSize]="900" [seFeDataCenter]="true"></se-fe-spinner>
</div>

<div *ngIf="selectedItemsLoaded">
  <se-fe-grid seFeGridOpts="spaced">
    <se-fe-grid-cell seFeGridOpts>
      <div class="item-vartiation-select">
        <se-fe-modal-button
          [seFeDataButton]="{ label: 'Select Eligible Items', secondary: true }"
          (seFeButtonAction)="eligibleItemModal.open($event)"></se-fe-modal-button>
        <div class="items-selected">
          {{ this.amountSelected + (this.amountSelected === 1 ? ' sale item selected' : ' sale items selected') }}
        </div>
      </div>
    </se-fe-grid-cell>
  </se-fe-grid>

  <div [hidden]="this.amountSelected < 1">
    <se-fe-grid seFeGridOpts>
      <se-fe-grid-cell seFeGridOpts>
        <div>
          <table #displayTable seFeTable title="Selected Items">
            <ng-container seFeColumnDef="name">
              <th seFeHeaderCell seFeDataRef="name" *seFeHeaderCellDef>Sale Item</th>
              <td seFeCell *seFeCellDef="let row">{{ row.full_name }}</td>
            </ng-container>

            <ng-container seFeColumnDef="sku">
              <th seFeHeaderCell seFeDataRef="sku" *seFeHeaderCellDef>SKU</th>
              <td seFeCell *seFeCellDef="let row">{{ row.sku }}</td>
            </ng-container>

            <ng-container seFeColumnDef="price">
              <th seFeHeaderCell seFeDataRef="price" *seFeHeaderCellDef>Price</th>
              <td seFeCell *seFeCellDef="let row">{{ row.price | currency }}</td>
            </ng-container>

            <ng-container seFeColumnDef="remove">
              <th seFeHeaderCell *seFeHeaderCellDef></th>
              <td seFeCell *seFeCellDef="let row" width="10">
                <se-fe-button [seFeDataEmphasis]="'medium'" (click)="removeItem(row.id)">Remove</se-fe-button>
              </td>
            </ng-container>

            <tr seFeHeaderRow *seFeHeaderRowDef="displayTableColumns.value; sticky: false"></tr>
            <tr seFeRow *seFeRowDef="let row; columns: displayTableColumns.value"></tr>
          </table>
        </div>
        <div>
          {{ this.amountSelected + (this.amountSelected === 1 ? ' sale item selected' : ' sale items selected') }}
        </div>
      </se-fe-grid-cell>
    </se-fe-grid>
  </div>

  <se-fe-modal #eligibleItemModal seFeDataSize="large">
    <div seFeModalHeader>
      <se-fe-modal-header seFeDataTitle="Select Eligible Items"></se-fe-modal-header>
    </div>

    <div seFeModalContent>
      <se-fe-grid seFeGridOpts>
        <se-fe-grid-cell seFeGridOpts>
          <div>{{ modalHeader }}</div>
        </se-fe-grid-cell>
      </se-fe-grid>
      <se-fe-grid seFeGridOpts>
        <se-fe-grid-cell seFeGridOpts>
          <se-fe-toolbar [seFeDataQuery]="searchTerm" (seFeQueryChange)="search($event)"></se-fe-toolbar>
          <div *ngIf="!isLoaded">
            <se-fe-spinner [seFeDataSize]="900" [seFeDataCenter]="true"></se-fe-spinner>
          </div>
          <div [hidden]="!isLoaded || itemVariationList.length">
            <se-fe-empty-state seFeDataTitle="No Results" seFeDataSize="500">
              Try adding sale items or changing your search terms
            </se-fe-empty-state>
          </div>
          <form *ngIf="selectForm" [formGroup]="selectForm">
            <div *ngIf="isLoaded && itemVariationList.length">
              <table #selectTable seFeTable title="Select Eligible Items" style="max-height: 400px">
                <ng-container seFeColumnDef="checkbox">
                  <th seFeHeaderCell *seFeHeaderCellDef>
                    <se-fe-checkbox formControlName="selectAll" [seFeDataOptions]="selectAllOptions"></se-fe-checkbox>
                  </th>
                  <td seFeCell *seFeCellDef="let row">
                    <se-fe-checkbox
                      [formControlName]="row.id"
                      [seFeDataOptions]="[{ label: 'check', value: true, hiddenLabel: true }]"></se-fe-checkbox>
                  </td>
                </ng-container>

                <ng-container seFeColumnDef="name">
                  <th seFeHeaderCell seFeDataRef="name" *seFeHeaderCellDef>Name</th>
                  <td seFeCell *seFeCellDef="let row">{{ row.full_name }}</td>
                </ng-container>

                <ng-container seFeColumnDef="sku">
                  <th seFeHeaderCell seFeDataRef="sku" *seFeHeaderCellDef>SKU</th>
                  <td seFeCell *seFeCellDef="let row">{{ row.sku }}</td>
                </ng-container>

                <ng-container seFeColumnDef="price">
                  <th seFeHeaderCell seFeDataRef="price" *seFeHeaderCellDef>Price</th>
                  <td seFeCell *seFeCellDef="let row">{{ row.price | currency }}</td>
                </ng-container>

                <tr seFeHeaderRow *seFeHeaderRowDef="selectTableColumns.value; sticky: false"></tr>
                <tr seFeRow *seFeRowDef="let row; columns: selectTableColumns.value"></tr>
              </table>
              <se-fe-pagination
                *ngIf="isLoaded"
                [seFeDataPaginationOptions]="paginationOptions"
                (seFePaginationChange)="handlePagination($event)"></se-fe-pagination>
            </div>
          </form>
        </se-fe-grid-cell>
      </se-fe-grid>
    </div>

    <div seFeModalFooter>
      <div style="display: flex; justify-content: flex-end; align-items: center; padding: 1em">
        <div class="se-dialog-button-group">
          <se-fe-modal-button
            [seFeDataButton]="{ label: 'Cancel', cancel: true }"
            (seFeButtonAction)="resetSelection()"></se-fe-modal-button>
          <se-fe-modal-button
            [seFeDataButton]="{ label: 'Submit', primary: true }"
            (seFeButtonAction)="saveSelection()"></se-fe-modal-button>
        </div>
      </div>
    </div>
  </se-fe-modal>
</div>
