import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// configuration
// models
import { Discount } from '../models/discount.model';

// components
import { EditableStepComponent } from '../editable-step/editable-step.component';

// services
import { SeFeScrollService, SeFeScrollToAlignment } from 'se-fe-scroll';
import { DiscountFormService } from '../services/discount-form.service';
import { DiscountService } from '../services/discount.service';

// providers
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';

// third party packages
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SeFeTableComponent, SeFeTableDataSource } from 'se-fe-table';
import { SeFeToastService, SeFeToastTypes } from 'se-fe-toast';
import { ItemVariationService } from '../../sale-items/services/item-variation.service';
import { LoadScriptsService } from '../../services/load-scripts.service';
import { SeFeFormFieldConfig } from 'se-fe-form-field';
import { LaunchDarklyService } from '../services/launch-darkly.service';

@Component({
  selector: 'app-discount-form',
  templateUrl: './discount-form.component.html',
  styleUrls: ['./discount-form.component.scss'],
})
export class DiscountFormComponent implements OnInit, OnDestroy {
  @Input() public discount: Discount;
  @Input() public formMode: string;
  @Output() public updateSummary = new EventEmitter<void>();

  public discountForm: UntypedFormGroup;
  public discountType: string;
  public discountTypeFormGroup: UntypedFormGroup;
  public discountNameFormGroup: UntypedFormGroup;
  public discountCodeFormGroup: UntypedFormGroup;
  public discountValueFormGroup: UntypedFormGroup;
  public discountScopeFormGroup: UntypedFormGroup;
  public discountRequirementsFormGroup: UntypedFormGroup;
  public discountDatesFormGroup: UntypedFormGroup;
  public maxRedeemableFormGroup: UntypedFormGroup;
  public codeCountFormGroup: UntypedFormGroup;

  customerBuysFormGroup: FormGroup;
  customerGetsFormGroup: FormGroup;

  public currentStep: string;
  public currentIndex = 0;
  public formSubmitting = false;
  public hasSubmissionErrors = false;
  public discountedItemsTableDataSource: SeFeTableDataSource;
  public requiredItemsTableDataSource: SeFeTableDataSource;
  public saleItemTableColumns = new BehaviorSubject<string[]>(['name', 'sku', 'price']);
  public discountedItemCount: number;
  public requiredItemCount: number;
  public steps: string[] = [];
  public showMaxValue = false;
  public showDiscountedItems = true;
  public submissionErrors: string[];
  public subscriptions: Subscription;

  @ViewChild(SeFeTableComponent) public discountedItemsTable!: SeFeTableComponent<ElementRef>;
  @ViewChild('discountedItemsTable') set discountedItemsTableComponent(
    discountedItemsTable: SeFeTableComponent<ElementRef>
  ) {
    if (!discountedItemsTable) {
      return;
    }
    this.discountedItemsTable = discountedItemsTable;
    this.discountedItemsTable.dataSource = this.discountedItemsTableDataSource;
  }

  @ViewChild(SeFeTableComponent) public requiredItemsTable!: SeFeTableComponent<ElementRef>;
  @ViewChild('requiredItemsTable') set requiredItemsTableComponent(requiredItemsTable: SeFeTableComponent<ElementRef>) {
    if (!requiredItemsTable) {
      return;
    }
    this.requiredItemsTable = requiredItemsTable;
    this.requiredItemsTable.dataSource = this.requiredItemsTableDataSource;
  }

  discountAmountConfig: SeFeFormFieldConfig = {
    label: 'Amount',
    required: true,
    errorMessages: {
      required: 'Please enter an amount.',
      min: 'Value must be greater than 0.',
      pattern: 'Please enter a valid amount.',
    }
  }

  customerBuysNumberOfItemsConfig: SeFeFormFieldConfig = {
    label: 'Number of items',
    required: true,
    errorMessages: {
      required: 'Please enter a number of items.',
      pattern: 'Please enter a valid number.',
    }
  }

  discountWhatConfig: SeFeFormFieldConfig = {
    label: 'At what discount?',
    required: true,
    errorMessages: {
      required: 'Please select a value.',
    }
  }

  discountWhatOptions = [
    {
      value: 'percentage',
      label: 'Percentage',
    },
    {
      value: 'amount',
      label: 'Dollar Amount',
    },
    {
      value: 'free',
      label: 'Free',
    }
  ];

  discountWhatScopeConfig: SeFeFormFieldConfig = {
    label: 'On how many items?',
    required: true,
    errorMessages: {
      required: 'Please select a scope for how many items.',
      min: 'Value must be greater than 0.'
    }
  }

  discountWhatScopeOptions = [
    {
      value: 'item',
      label: 'Specific number of items',
    },
    {
      value: 'order',
      label: 'All eligible items',
    }
  ];

  discountWhatLimitConfig: SeFeFormFieldConfig = {
    label: 'Limit the number of uses per order?'
  };

  discountWhatLimitOptions = [
    {
      label: 'Set a maximum number of uses per order',
      value: true,
    }
  ]

  discountWhatLimitValueConfig: SeFeFormFieldConfig = {
    label: 'Maximum',
    errorMessages: {
      required: 'Please enter a maximum number of uses.',
      min: 'Value must be greater than 0.',
      pattern: 'Please enter a valid number.',
    }
  }

  get customerGetsControl(): FormControl {
    return this.customerGetsFormGroup.get('customerGets') as FormControl;
  }

  get customerBuysControl(): FormControl {
    return this.customerBuysFormGroup.get('customerBuys') as FormControl;
  }

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: string,
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2,
    public discountFormService: DiscountFormService,
    public discountService: DiscountService,
    private itemVariationService: ItemVariationService,
    private loadScriptsService: LoadScriptsService,
    private router: Router,
    private seFeScrollService: SeFeScrollService,
    private translateService: TranslateService,
    private toastService: SeFeToastService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.discountedItemsTableDataSource = new SeFeTableDataSource(undefined);
    this.requiredItemsTableDataSource = new SeFeTableDataSource(undefined);
    this.loadScriptsService.loadScripts('saleItemSelector');
    this.subscriptions = new Subscription();
    this.buildForm();
    if (this.formMode === 'edit') {
      this.showMaxValue = this.discount.maximum_discount_value_cents > 0;
      const type = this.discountService.getDiscountType(this.discount);
      this.steps = this.discountFormService.getStepsForType(type);
      this.currentIndex = this.steps.length - 1;
      this.currentStep = this.steps[this.currentIndex];
      this.updateValueFormGroup();
      this.updateScopeFormGroup();
      this.updateRequirementsFormGroup();
      this.updateMaxRedeemableFormGroup();
      this.loadSelectedItems();
    } else {
      this.currentStep = 'discount_type';
    }
    [
      this.discountForm.valueChanges.subscribe(() => this.updateForm()),
      this.discountTypeFormGroup.valueChanges.subscribe((values: {type: string}) => {
        this.setType(values.type);
        if (values.type === 'automatic') {
          this.discountFormService.addBuyXGetYOption();
        } else {
          this.discountFormService.removeBuyXGetYOption();
        }
      }),
      this.discountNameFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_name', this.discountNameFormGroup)
      ),
      this.discountCodeFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_code', this.discountCodeFormGroup)
      ),
      this.discountValueFormGroup.valueChanges.subscribe((value: {value_type: string}) => {
        this.checkStep('discount_value', this.discountValueFormGroup);
      }),
      this.discountScopeFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_scope', this.discountScopeFormGroup)
      ),
      this.discountRequirementsFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_requirements', this.discountRequirementsFormGroup)
      ),
      this.maxRedeemableFormGroup.valueChanges.subscribe(() =>
        this.checkStep('max_redeemable', this.maxRedeemableFormGroup)
      ),
      this.discountDatesFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_dates', this.discountDatesFormGroup)
      ),
      this.codeCountFormGroup.valueChanges.subscribe(() => this.checkStep('code_count', this.codeCountFormGroup)),
      this.discountValueFormGroup.controls.value_type.valueChanges.subscribe(value => {
        if (!value) return;

        this.goToStep('discount_value');

        if (value === 'buyXGetY') {
          this.steps = this.discountFormService.getBuyXGetYSteps(this.steps);
        } else {
          const type = this.discountTypeFormGroup.value.type;
          this.steps = this.discountFormService.getStepsForType(type);
        }

        this.updateValueFormGroup();
      }),
      this.discountScopeFormGroup.controls.scope_type.valueChanges.subscribe(() => this.updateScopeFormGroup()),
      this.discountScopeFormGroup.controls.max_eligible_item_type.valueChanges.subscribe(() => {
        this.discountFormService.setMaxEligibleCountValidation(this.discountScopeFormGroup);
      }),
      this.discountRequirementsFormGroup.controls.requirement_type.valueChanges.subscribe(() => {
        this.updateRequirementsFormGroup();
      }),
      this.maxRedeemableFormGroup.controls.max_use.valueChanges.subscribe(() => this.updateMaxRedeemableFormGroup()),

      this.customerGetsFormGroup.controls.discountWhat.valueChanges.subscribe((value) => {
        if (!value) return;

        if (value === 'amount') {
          this.customerGetsFormGroup.controls.discountValue.setValidators([Validators.required, Validators.min(0.01), Validators.pattern(/^\d+(\.\d{1,2})?$/)])
        } else if (value === 'percentage') {
          this.customerGetsFormGroup.controls.discountValue.setValidators([Validators.required, Validators.min(1)]);
        } else {
          this.customerGetsFormGroup.controls.discountValue.clearValidators();
        }

        this.customerGetsFormGroup.controls.discountValue.updateValueAndValidity();
      }),
      this.customerGetsFormGroup.controls.discountWhatScope.valueChanges.subscribe(value => {
        if (!value) return;

        if (value === 'item') {
          this.customerGetsFormGroup.controls.howManyItems.setValidators(
            [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]
          );
        } else {
          this.customerGetsFormGroup.controls.howManyItems.clearValidators();
        }

        this.customerGetsFormGroup.controls.howManyItems.updateValueAndValidity();
      }),
      this.customerGetsFormGroup.controls.limitNumberOfUses.valueChanges.subscribe(value => {
        if (value) {
          this.customerGetsFormGroup.controls.maxUses.setValidators(
            [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]
          );
        } else {
          this.customerGetsFormGroup.controls.maxUses.clearValidators();
        }
        this.customerGetsFormGroup.controls.maxUses.updateValueAndValidity();
      }),
    ].forEach((sub) => this.subscriptions.add(sub));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isReusable(): boolean {
    if (!this.discountTypeFormGroup) {
      return false;
    }
    return ['reusable', 'automatic'].includes(this.discountTypeFormGroup.value.type);
  }

  public canEdit(step) {
    if (this.formMode === 'create') {
      return true;
    }
    if (step === 'discount_type' || step === 'discount_code') {
      return false;
    }
    return this.discount.used_code_count === 0;
  }

  public toggleMaxValue(): void {
    this.discountFormService.setMaxValueValidation(this.discountValueFormGroup, !this.showMaxValue);
    this.showMaxValue = !this.showMaxValue;
  }

  public updateDiscountedItems(items: number[]): void {
    this.discountScopeFormGroup.controls.discounted_items.setValue(items.join(','));
  }

  public updateRequiredItems(items: number[]): void {
    this.discountRequirementsFormGroup.controls.required_items.setValue(items.join(','));
  }

  public showEmptyState(): boolean {
    return !this.steps.length;
  }

  public showStep(stepID: string): boolean {
    if (this.steps) {
      if (this.steps.indexOf(stepID) > 0) {
        return this.steps.indexOf(stepID) <= this.steps.indexOf(this.currentStep);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public excludeDatesAfterEnd() {
    if (!this.discountDatesFormGroup.value.expires_at) {
      return [];
    }
    const end_date = DateTime.fromFormat(
      this.discountDatesFormGroup.value.expires_at,
      this.discountFormService.UI_DATE_FORMAT
    );
    return [{ start: end_date.plus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT) }];
  }

  public excludeDatesBeforeStart() {
    if (!this.discountDatesFormGroup.value.starts_at) {
      return [];
    }
    const start_date = DateTime.fromFormat(
      this.discountDatesFormGroup.value.starts_at,
      this.discountFormService.UI_DATE_FORMAT
    );
    return [{ end: start_date.minus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT) }];
  }

  public validateAndStep(step: EditableStepComponent): void {
    if (step.formGroup.valid) {
      if (step.isLastStep()) {
        this.submitForm();
      } else {
        this.goToStep(this.steps[this.currentIndex + 1]);
      }
    } else {
      Object.keys(step.formGroup.controls).forEach((key) => {
        step.formGroup.controls[key].setValue(step.formGroup.controls[key].value);
        step.formGroup.controls[key].markAsDirty();
        step.formGroup.controls[key].markAsTouched();
      });
    }
  }

  getDiscountValueDescription(value: string): string {
    switch (value) {
      case 'amount': return 'A set dollar amount off the cart subtotal or specific items you choose';
      case 'percentage': return 'A percentage off the cart subtotal or specific items you choose';
      case 'buyXGetY': return 'Great for making “sibling” discounts where your customers get a discount ' +
        'for registering more than one athlete or buying more of the same item. ' +
        'Commonly used for BOGO discounts.';
    }
  }

  getDiscountWhatDescription(value: string): string {
    const count = Number(this.customerBuysFormGroup.value.minimumItemCount);
    const item = count === 1 ? 'item' : 'items';
    switch (value) {
      case 'percentage': return 'Customer gets a percentage off all eligible items';
      case 'amount': return 'Customer gets a dollar amount off all eligible items';
      case 'free': return 'Customer gets all eligible items for free';
      case 'item': return `For every ${count} eligible ${item} bought, customer gets a certain number of items at a discount`;
      case 'order': return `If customer buys ${count} eligible ${item}, customer gets all other eligible items in the order at a discount`;
    }
  }

  private updateForm(): void {
    this.mapFormToModel();
    this.updateSummary.emit();
  }

  private setType(type: string): void {
    this.discount.single_use = !this.isReusable();
    this.resetForm();
    this.steps = this.discountFormService.getStepsForType(type);
  }

  private checkStep(step: string, formGroup: UntypedFormGroup): void {
    if (!this.steps.length) {
      return;
    }
    if (this.currentIndex < this.steps.indexOf(step)) {
      return;
    }
    if (formGroup.invalid && this.currentIndex > this.steps.indexOf(step)) {
      this.goToStep(step);
      if (this.formMode === 'create') {
        this.resetSubsequentSteps(step);
      }
    }
  }

  private goToStep(step: string): void {
    this.currentStep = step;
    this.currentIndex = this.steps.indexOf(step);
    const element = document.getElementById(step);
    if (element) {
      this.seFeScrollService.scrollTo(SeFeScrollToAlignment.Top, element);
    }
  }

  private updateValueFormGroup(): void {
    this.discountFormService.setValueValidation(this.discountValueFormGroup, this.showMaxValue);
    this.discountValueFormGroup.controls.value_amount.updateValueAndValidity();
    if (this.discountValueFormGroup.controls.value_type.value === 'amount') {
      this.showMaxValue = false;
    }
  }

  private updateScopeFormGroup(): void {
    this.showDiscountedItems = this.discountScopeFormGroup.controls.scope_type.value === 'Item';
    this.discountFormService.setScopeValidation(this.discountScopeFormGroup, this.showDiscountedItems);
  }

  private updateRequirementsFormGroup(): void {
    this.discountFormService.setRequirementsValidation(this.discountRequirementsFormGroup);
  }

  private updateMaxRedeemableFormGroup(): void {
    this.discountFormService.setMaxRedeemableValidation(this.maxRedeemableFormGroup, this.discount.used_code_count);
  }

  updateCustomerBuys(itemIds: number[]): void {
    this.customerBuysFormGroup.get('customerBuys').setValue(itemIds);
  }

  updateCustomerGets(itemIds: number[]): void {
    this.customerGetsFormGroup.get('customerGets').setValue(itemIds);
  }

  private mapFormToModel(): void {
    this.discount.discount_style = this.discountForm.value.discountType.type === 'automatic' ? 'Automatic' : 'Code';
    this.discount.name = this.discountForm.value.discountName.name;
    this.discount.expires_at = this.discountFormService.formatDateForSubmission(
      this.discountForm.value.discountDates.expires_at
    );
    this.discount.starts_at = this.discountFormService.formatDateForSubmission(
      this.discountForm.value.discountDates.starts_at
    );
    this.discount.max_use_count = this.discountForm.value.maxRedeemable.max_use_count;

    if (this.discountValueFormGroup.value.value_type === 'buyXGetY') {
      this.mapBuyXGetYFormToModel();
    } else {
      this.discount.buyx_gety = false;
      this.discount.max_use = this.discountForm.value.maxRedeemable.max_use;
      this.discount.additional_code_count = this.discountForm.value.codeCount.additional_code_count;
      this.discount.code_count = this.discountForm.value.codeCount.code_count;
      this.discount.discount_scope = this.discountForm.value.discountScope.scope_type;
      this.discount.discount_type = this.discountForm.value.discountValue.value_type;
      this.discount.discount_value = this.discountForm.value.discountValue.value_amount;
      this.discount.discounted_items = this.discountForm.value.discountScope.discounted_items;
      this.discount.max_eligible_items = this.discountFormService.getMaxEligibleCountValue(
        this.discountForm.value.discountScope
      );
      this.discount.maximum_discount_value = this.discountForm.value.discountValue.maximum_discount_value;
      this.discount.minimum_sale_total = this.discountForm.value.discountRequirements.minimum_sale_total;
      this.discount.required_items = this.discountForm.value.discountRequirements.required_items;
      this.discount.required_items_quantity = this.discountForm.value.discountRequirements.required_items_quantity;
      this.discount.requirement_type = this.discountForm.value.discountRequirements.requirement_type;
      this.discount.supplied_code = this.isReusable() ? this.discountForm.value.discountCode.supplied_code : null;
    }
  }

  private mapBuyXGetYFormToModel(): void {
    this.discount.buyx_gety = true;
    this.discount.required_item_variation_ids = this.customerBuysFormGroup.value.customerBuys;
    this.discount.required_items_quantity = this.customerBuysFormGroup.value.minimumItemCount;

    this.discount.discounted_item_variation_ids = this.customerGetsFormGroup.value.customerGets;
    this.discount.discount_type = this.customerGetsFormGroup.value.discountWhat;
    this.discount.discount_value = this.discount.discount_type === 'free' ? 0 : this.customerGetsFormGroup.value.discountValue;
    this.discount.discount_scope = this.customerGetsFormGroup.value.discountWhatScope;
    this.discount.max_eligible_items = this.customerGetsFormGroup.value.howManyItems || null;
  }

  private buildForm(): void {
    this.discountTypeFormGroup = this.discountFormService.getDiscountTypeFormGroup(this.discount);
    this.discountNameFormGroup = this.discountFormService.getDiscountNameFormGroup(this.discount);
    this.discountCodeFormGroup = this.discountFormService.getDiscountCodeFormGroup(this.discount);
    this.discountValueFormGroup = this.discountFormService.getDiscountValueFormGroup(this.discount);
    this.discountScopeFormGroup = this.discountFormService.getDiscountScopeFormGroup(this.discount);
    this.discountRequirementsFormGroup = this.discountFormService.getDiscountRequirementsFormGroup(this.discount);
    this.maxRedeemableFormGroup = this.discountFormService.getMaxRedeemableFormGroup(this.discount);
    this.discountDatesFormGroup = this.discountFormService.getDiscountDatesFormGroup(this.discount);
    this.codeCountFormGroup = this.discountFormService.getCodeCountFormGroup(this.discount);

    this.customerBuysFormGroup = new FormGroup({
      customerBuys: new FormControl([], [Validators.required]),
      minimumItemCount: new FormControl<number>(
        null,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern('^[0-9]*$')
        ]
      ),
    });

    this.customerGetsFormGroup = new FormGroup({
      customerGets: new FormControl([], [Validators.required]),
      discountWhat: new FormControl<string>(null, [Validators.required]),
      discountValue: new FormControl<number>(null),
      discountWhatScope: new FormControl<string>(null, [Validators.required]),
      howManyItems: new FormControl<number>(null),
      limitNumberOfUses: new FormControl<boolean>(false),
      maxUses: new FormControl<number>(null),
    });

    this.discountForm = new UntypedFormGroup({
      discountType: this.discountTypeFormGroup,
      discountName: this.discountNameFormGroup,
      discountCode: this.discountCodeFormGroup,
      discountValue: this.discountValueFormGroup,
      discountScope: this.discountScopeFormGroup,
      discountRequirements: this.discountRequirementsFormGroup,
      maxRedeemable: this.maxRedeemableFormGroup,
      discountDates: this.discountDatesFormGroup,
      codeCount: this.codeCountFormGroup,
      customerBuys: this.customerBuysFormGroup,
      customerGets: this.customerGetsFormGroup,
    });
  }

  private resetForm() {
    this.currentStep = 'discount_type';
    this.currentIndex = 0;
    this.showMaxValue = false;
    this.showDiscountedItems = false;
    this.hasSubmissionErrors = false;
    this.submissionErrors = [];
    this.resetSubsequentSteps('discount_type');
  }

  private resetSubsequentSteps(current_step: string): void {
    const current_step_index = this.steps.indexOf(current_step);
    this.steps.forEach((step, index) => {
      if (index > current_step_index) {
        switch (step) {
          case 'discount_name':
            this.discountNameFormGroup.reset();
            break;
          case 'discount_code':
            this.discountCodeFormGroup.reset();
            break;
          case 'discount_value':
            this.discountValueFormGroup.reset();
            break;
          case 'discount_scope':
            this.discountScopeFormGroup.reset();
            break;
          case 'discount_requirements':
            this.discountRequirementsFormGroup.reset();
            break;
          case 'max_redeemable':
            this.maxRedeemableFormGroup.reset();
            break;
          case 'discount_dates':
            this.discountFormService.setDiscountDates(this.discount, this.discountDatesFormGroup);
            Object.keys(this.discountDatesFormGroup.controls).forEach((key) => {
              this.discountDatesFormGroup.controls[key].markAsPristine();
              this.discountDatesFormGroup.controls[key].markAsUntouched();
            });
            break;
          case 'code_count':
            this.codeCountFormGroup.reset();
            break;
          case 'customer_buys':
            this.customerBuysFormGroup.reset();
            break;
          case 'customer_gets':
            this.customerGetsFormGroup.reset();
            break;
        }
      }
    });
  }

  private async submitForm() {
    this.currentIndex = this.steps.length;
    this.discount.organization_id = this.currentOrgId.toString();
    this.formSubmitting = true;
    await this.discountService
      .save(this.discount)
      .then(() => {
        const goTo = this.discount.id ? `/discounts/${this.discount.id}/detail` : '/discounts';
        const successKey = this.discount.id ? 'DISCOUNTS.EDIT_DISCOUNT.success' : 'DISCOUNTS.ADD_DISCOUNT.success';
        this.router.navigate([goTo]);
        const successMessage = this.translateService.instant(successKey, { name: this.discount.name });
        this.toastService.addToast({
          type: SeFeToastTypes.Success,
          message: successMessage,
        });
      })
      .catch((e) => {
        this.formSubmitting = false;
        this.hasSubmissionErrors = true;
        this.submissionErrors = e.error.error.messages;
        this.seFeScrollService.scrollTo(SeFeScrollToAlignment.Top, document.getElementById('submission_errors'), {
          offset: 115,
        });
      });
  }

  private loadSelectedItems(): void {
    if (this.discount.discounted_items?.length) {
      const queryParams = {
        id_list: this.discount.discounted_items,
        'order[full_name]': 'asc',
        unpaginated: true,
        include_zero_rate: 1,
      };
      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        this.discountedItemsTableDataSource.data = response.result;
        this.discountedItemCount = response.result.length;
      });
    }
    if (this.discount.required_items?.length) {
      const queryParams = {
        id_list: this.discount.required_items,
        'order[full_name]': 'asc',
        include_zero_rate: 1,
      };
      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        this.requiredItemsTableDataSource.data = response.result;
        this.requiredItemCount = response.result.length;
      });
    }
  }
}
